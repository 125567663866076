import environment from '../../environment/environment';
import addStyleLink from '../addStyleLink';
import addInlineStyle from './addInlineStyle';
import addLegacyStyleSheets from './addLegacyStylesheets';

export default configuration => {
    const head = document.head;

    addStyleLink(head, {
        type: 'stylesheet',
        href: 'https://fonts.googleapis.com/css?family=Libre+Franklin:400,400i,700,700i|Roboto:400,500,700,900',
    });

    addInlineStyle(head, configuration);

    if (configuration.compatibilityMode) {
        addLegacyStyleSheets(head, configuration);

        addStyleLink(head, {
            type: 'stylesheet',
            href: `${environment.ewn.style}`,
        });
    }

    // if (environment.googleAnalytics.hasGoogleAnalytics) {
    //     const googleAnalyticsScript = `
    //         var siteId = '${environment.googleAnalytics.propertyId}';

    //         (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
    //         (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
    //         m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
    //         })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    //         ga('create', siteId, 'auto');
    //         ga('send', 'pageview');

    //     `;

    //     let googleAnalyticsScriptElement = document.createElement('script');
    //     googleAnalyticsScriptElement.innerHTML = googleAnalyticsScript;
    //     head.appendChild(googleAnalyticsScriptElement);
    // }
};